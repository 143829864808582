(function(){
    "use strict";

    angular
        .module('elogbooksControllers')
        .controller(
            'ChangePartnershipController',
            function (
                siteCollection,
                serviceProviderResponse,
                partnershipsResponse,
                apiClient,
                $state,
                $rootScope,
                messenger,
                validationService
            ) {
            var vm = this;
            vm.siteCollection = siteCollection;
            vm.partnershipsResponse = partnershipsResponse;
            vm.validationService = validationService;
            vm.grantAllActiveSites = grantAllActiveSites;
            vm.deactivateAllSites = deactivateAllSites;

            function grantAllActiveSites() {
                $rootScope.skipDirtyCheck = true;
                var requestData = {
                    _links: {
                        site: []
                    },
                    activateAllPartnerships: '1'
                };

                angular.forEach(vm.siteCollection.sites, function(site) {
                    requestData._links.site.push(site._links.self);  
                })

                apiClient
                    .create(serviceProviderResponse.getLink('partnerships'), requestData)
                    .then(function(response) {
                        if (response) {
                            $state.go('dashboard.admin.service-providers.list.details.partnerships', {}, { reload: true }).then(function() {
                                $rootScope.skipDirtyCheck = false;
                                messenger.success('PARTNERSHIPS_UPDATED');
                            });
                        }
                });
            }

            function deactivateAllSites() {
                $rootScope.skipDirtyCheck = true;
                var requestData = { deactivateAllPartnerships: '1' };

                apiClient
                    .update(serviceProviderResponse.getLink('partnerships'), requestData)
                    .then(function(response) {
                        if (response) {
                            $state.go('dashboard.admin.service-providers.list.details.partnerships', {}, { reload: true }).then(function() {
                                $rootScope.skipDirtyCheck = false;
                                messenger.success('PARTNERSHIPS_UPDATED');
                            });
                        }
                });
            }
    });
})();
