(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            JOB: 'Job',
            JOBS: 'Jobs',
            JOB_LIST: 'Jobs',

            JOB_REACTIVE_APPROVAL_REQUIRED:' Reactive Job Approvals',
            JOB_VALUE_APPROVALS: 'Job Value Approvals',

            LOG_A_NEW_JOB: 'Log a new Job',
            ADD_NEW_JOB: 'Add a new Job',
            JOB_ADD: 'Add a Job',
            ALL_JOBS: 'Jobs',

            CREATED_AT_START: 'Created At Start',
            CREATED_AT_END: 'Created At End',
            ALARMED_AT_START: 'Alarmed At Start',
            ALARMED_AT_END: 'Alarmed At End',
            REMINDER_AT_START: 'Reminder At Start',
            REMINDER_AT_END: 'Reminder At End',
            ATTENDANCE_DUE_AT_START: 'Attendance Due At Start',
            ATTENDANCE_DUE_AT_END: 'Attendance Due At End',
            COMPLETION_DUE_AT_START: 'Completion Due At Start',
            COMPLETION_DUE_AT_END: 'Completion Due At End',
            LAST_SUBMITTED_AT: 'Last Submitted At',
            LAST_SUBMITTED_AT_START: 'Last Submitted At Start',
            LAST_SUBMITTED_AT_END: 'Last Submitted At End',
            COMPLETED_AT_START: 'Completed At Start',
            COMPLETED_AT_END: 'Completed At End',

            // Job details screen
            JOB_ID: 'Job #{{ ::id }}',
            ID_JOB: 'Job ID',
            JOB_DETAILS: 'Job Details',
            JOB_DETAILS_WITH_ID: 'Job #{{ ::id }} details',
            JOB_STATUS: 'Status',
            JOB_EVENTS: 'Events',
            // JOB_MESSAGE_ACTION: '<strong>{{ ::message.sender }}</strong> added a message.',
            JOB_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created the job.',
            JOB_FILE_DELETED: '<strong>{{ ::event._links["user"].title }}</strong> deleted a job file.',
            JOB_LINKED_TO_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added a job to link',
            JOB_LINKED_RELATE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added a related job link',
            JOB_LINKED_FROM_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added a job from link',
            JOB_LINKED_FROM_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added a link from job to quote',
            JOB_LINKED_RELATES_QUOTE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added a link from job relating to quote',
            JOB_MESSAGE_ACTION: '<strong>{{ ::message._links["sender"].title }}</strong> provided an update.',
            JOB_ACTIONED_ACTION: 'Action required deadline was advanced.',
            JOB_APPROVAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an approval request.',
            JOB_APPROVAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> approved the job.',
            JOB_APPROVAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the job approval.',
            JOB_APPROVAL_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> resent an approval request to {{ ::event._links["approver"].title }}.',
            JOB_ASSIGNMENT_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an assignment request to {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_REASSIGNED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reassigned the job.',
            JOB_ASSIGNMENT_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the job.',
            JOB_ASSIGNMENT_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the job.',
            JOB_ASSIGNMENT_RESENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> resent an assignment request to {{ ::event._links["operative"].title }}.',
            JOB_ASSIGNMENT_INSISTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> insists the job is accepted.',
            JOB_PROPOSAL_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent a proposal.',
            JOB_PROPOSAL_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the proposal.',
            JOB_PROPOSAL_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the proposal.',
            JOB_PROPOSAL_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the proposal.',
            JOB_PROPOSAL_ACCEPTED_SYSTEM_ACTION: 'The system automatically accepted the extension request.',
            JOB_EXTENSION_REQUESTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent an extension request.',
            JOB_EXTENSION_ACCEPTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> accepted the extension request.',
            JOB_EXTENSION_ACCEPTED_SYSTEM_ACTION: 'The system automatically accepted the extension request.',
            JOB_APPROVAL_ACCEPTED_SYSTEM_ACTION: 'The system approved the job',
            JOB_EXTENSION_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the extension request.',
            JOB_EXTENSION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the extension request.',
            JOB_OPERATION_ACKNOWLEDGED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> acknowledged the job.',
            JOB_OPERATION_COMMENCED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> commenced the job.',
            JOB_OPERATION_COMPLETED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> completed the job.',
            JOB_OPERATION_COMPLETED_PAPERWORK_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added paperwork for the job.',
            JOB_POSTPONE_REMINDER_AT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> changed the Action Required By date to {{ ::event.reminderAt | date:\'short\' }}.',
            JOB_OPERATION_MISSED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> missed the job.',
            JOB_OPERATION_CHASED_ACTION: 'A chase #{{ ::event.chaseSequence + 1 }} notification has been sent to <strong>{{ ::event._links["user"].title }}</strong>.',
            JOB_OPERATION_REOPENED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> reopened the job.',
            JOB_OPERATION_CANCELLED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the job.',
            JOB_APPROVAL_ESCALATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the job approval.',
            JOB_AUDIT_TRAIL_SENT_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> sent the audit trail.',
            JOB_ESCALATION_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> escalated the job.',
            JOB_ESCALATION_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> cancelled the escalation.',
            JOB_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created link',
            JOB_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed link',
            QUOTE_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created link',
            QUOTE_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed link',
            BUDGET_QUOTE_CONVERTED_SOURCE: '<strong>{{ ::event._links["user"].title }}</strong> converted Budget Quote {{ ::event.relationLink.source }} to Quote {{ ::event.relationLink.target }}',
            QUOTE_CONVERTED_TARGET: '<strong>{{ ::event._links["user"].title }}</strong> converted Budget Quote {{ ::event.relationLink.target }} to Quote {{ ::event.relationLink.source }}',
            ACTION_RELATION_LINK_CREATED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> created link',
            ACTION_RELATION_LINK_REMOVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> removed link',
            JOB_P2P_VALUE_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added values to the job.',
            JOB_VALUES_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> added values to the job.',
            JOB_VALUES_APPROVED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> approved the job\'s values.',
            JOB_VALUES_APPROVED_SYSTEM_ACTION: 'The system automatically approved the job\'s values.',
            JOB_VALUES_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the job\'s values.',
            JOB_FOC_ADDED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> marked job as free of charge.',
            JOB_ENTER_DETAILS_MANUALLY: 'Enter details manually',
            JOB_CHOOSE_A_USER: 'Choose a user',
            JOB_CHOOSE_A_TENANT: 'Choose a tenant',
            JOB_REPORTER_NAME: 'Reporter name',
            JOB_CONTACT_NAME: 'Contact name',
            JOB_PHONE_NUMBER: 'Phone number',
            JOB_RELATIONSHIP_TO_THE_PROPERTY: 'Relationship to the property',
            JOB_NOTIFY_ON_CREATION: 'Notify on creation',
            JOB_NOTIFY_ON_COMPLETION: 'Notify on completion',
            JOB_NOTIFY_ON_STATUTORY_COMPLETION: 'Notify on completion - Statutory',
            JOB_SKIP_REQUEST_APPROVAL_NOTIFICATION: 'Skip approval notification',
            JOB_NEXT_STEP: 'NEXT STEP',
            JOB_SAME_AS_CALLER: 'Same as caller',
            JOB_SITE_CONTACT_MYSELF: 'I am the Site Contact',
            JOB_SITE_CONTACT_AVAILABLE: 'Site contact available',
            JOB_TO_MEET_THE_CONTRACTOR: 'to meet the contractor',
            JOB_KEYHOLDER_REQUIRED: 'Keyholder required',
            JOB_SITE_PRIORITY: 'Site Prority',
            JOB_STEP_DETAIL: 'Details',
            JOB_SERVICE_PROVIDER_PRIORITY: 'Service provider priority',
            JOB_SYSTEM_PRIORITY: 'System Priority',
            JOB_PHONE_NUMBERS: 'Phone numbers',
            JOB_KEYHOLDER_DETAILS_PASS: 'Keyholder details passed by:',
            BY_PHONE: 'phone',
            BY_MAIL: 'mail',
            SYSTEM_MAIL: 'autogenerated mail',
            JOB_UPCOMING_PPM_EMAIL_ACTION: '<strong>Upcoming Planned Jobs Notification</strong>',
            JOB_EMAIL_EVENT_LIST: 'NOTIFICATIONS',
            CONTACT_NAME_REQUIRED: 'Contact Name Required',

            JOB_TYPE_REACTIVE: 'Reactive',
            JOB_TYPE_PLANNED: 'Planned',

            JOB_REPORTER: 'Reporter',
            JOB_CONTACT: 'Contact',
            JOB_ADD_SITE_DETAILS: 'Select a Site',
            JOB_ADD_REPORTER_DETAILS: 'Enter Reporter Details',
            JOB_ADD_CONTACT_DETAILS: 'Enter Site Contact Details',
            JOB_ADD_DETAILS: 'Enter Job Details',

            JOB_WORKFLOW_COMPLETE: 'Complete Job',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Complete Job',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'Complete Job',

            JOB_WORKFLOW_COMPLETE_PAPERWORK: 'Complete Paperwork',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION: 'Complete Paperwork',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUBMIT: 'Complete Paperwork',
            JOB_STATUS_AWAITING_PAPERWORK: 'Awaiting Paperwork',
            JOB_STATUS_PAPERWORK_REJECTED: 'Paperwork Rejected',
            COMPLETE_PAPERWORK: 'Complete Paperwork',

            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'Acknowledge',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Request New SLA',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Insist',

            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Approve',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Approve',

            JOB_WORKFLOW_REOPEN: 'Reopen',

            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Escalate Approval',

            JOB_WORKFLOW_ALARM: 'Edit Alarm',
            JOB_WORKFLOW_ALARM_SUBMIT: 'Update',
            JOB_WORKFLOW_ALARM_SUCCESS: 'Alarm Updated',

            JOB_WORKFLOW_ALARM_SETNEW: 'Set Alarm',
            JOB_WORKFLOW_ALARM_SETNEW_SUBMIT: 'Set',
            JOB_WORKFLOW_ALARM_SETNEW_SUCCESS: 'Alarm Set',

            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Reassign Assignment',

            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Change Action Required By Date',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Submit',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Job Action Required By Date Updated',

            JOB_WORKFLOW_ACCEPT_PROPOSAL: 'Accept New SLA',
            JOB_WORKFLOW_ACCEPT_PROPOSAL_SUBMIT: 'Confirm',
            PROPOSED: 'Proposed',

            JOB_WORKFLOW_REJECT_PROPOSAL: 'Reject New SLA',
            JOB_WORKFLOW_REJECT_PROPOSAL_SUBMIT: 'Submit',

            JOB_WORKFLOW_CANCEL_PROPOSAL: 'Cancel New SLA',
            JOB_WORKFLOW_CANCEL_PROPOSAL_SUBMIT: 'Submit',

            JOBS_LIST: 'Jobs',
            JOBS_ADD: 'Add a new Job',
            JOBS_EDIT: 'Edit Job',

            JOB_TYPE: 'Job Type',
            JOB_STAT_TYPE: 'Statutory type',
            JOB_SUMMARY: 'Job Summary',
            JOB_PRIORITY: 'Priority',
            UPDATE_TO_BE_REVIEWED: 'Update to be Reviewed',

            JOB_STATUS_ACKNOWLEDGED: 'Acknowledged',

            JOB_QUOTE_REQUEST_TO: 'To Quote',
            JOB_QUOTE_REQUEST_WITH: 'With Quote',
            JOB_QUOTE_REQUEST_FROM: 'From Quote',
            JOB_SENT_TO_QUOTE: 'Sent to Quote',
            JOB_RELATES_TO_QUOTE: 'Relates to Quote',

            JOB_STATUS_LEGEND: 'Job Status Legend',

            JOB_STATUS_CREATED: 'Created',
            JOB_STATUS_APPROVAL_PENDING: 'Pending Approval',
            JOB_STATUS_APPROVAL_REJECTED: 'Approval Rejected',

            JOB_STATUS_ASSIGNMENT_PENDING: 'Pending Acknowledgement',
            JOB_STATUS_ASSIGNMENT_PROPOSAL: 'SLA Proposal Pending',

            JOB_STATUS_ASSIGNMENT_REJECTED: 'Assignment Rejected',
            JOB_STATUS_OPERATIVE_ASSIGNMENT_REJECTED: 'Job Operative Assignment Rejected',

            JOB_STATUS_OPERATION_ACKNOWLEDGED: 'Acknowledged',
            JOB_STATUS_COMMENCED: 'In Progress',
            JOB_STATUS_OPERATION_MISSED: 'Missed',
            JOB_STATUS_OPERATION_CANCELLED: 'Cancelled',
            JOB_STATUS_COMPLETED: 'Completed',
            JOB_STATUS_OPERATION_AWAITING_PAPERWORK: 'Awaiting Paperwork',
            JOB_WORKFLOW_COMPLETE_PAPERWORK_OPERATION_SUCCESS: 'Paperwork Attached',
            JOB_WORKFLOW_REASSIGN_OPERATIVE: 'Reassign Job Operative',

            JOB_STATUS_EXTENSION_PENDING: 'Extension Request Pending',
            JOB_STATUS_EXTENSION_REJECTED: 'Extension Request Rejected',

            JOB_STATUS_MISSED: 'Missed',

            JOB_STATUS_OPEN: 'Open',
            JOB_STATUS_CLOSED: 'Closed',
            JOB_STATUS_ALL: 'All',

            RESEND_APPROVAL_REQUEST: 'Resend Approval Request',
            RESEND_ASSIGNMENT_REQUEST: 'Resend Assignment Request',
            REASSIGN_OPERATIVE: 'Reassign Operative',

            SEND_TO_QUOTE: 'Send to Quote',
            ESCALATE_TO_CLIENT: 'Escalate to Client',
            EDIT_JOB: 'Edit Job',

            FROM_JOB: 'Created from Job',
            LINK_FROM_JOB: 'From Job',
            LINK_TO_JOB: 'To Job',
            LINK_RELATES_TO_JOB: 'Relates to Job',

            FROM_QUOTE: 'Created from Quote',
            LINK_FROM_QUOTE: 'From Quote',
            LINK_TO_QUOTE: 'To Quote',
            LINK_RELATES_TO_QUOTE: 'Relates to Quote',

            LINK_TYPE: 'Link Type',
            JOB_LINK_SEARCH: 'Job Link Search',
            QUOTE_LINK_SEARCH: 'Quote Link Search',

            JOB_LINKS: 'Job Links',
            QUOTE_LINKS: 'Quote Links',
            ACTION_LINKS: 'Action Links',

            COMPLETION_DATE: 'Completion Date',
            NEW_COMPLETION_DATE: 'New Completion Date',
            ORIGINAL_COMPLETION_DATE: 'Original Completion Date',
            REASSIGN_ASSIGNMENT: 'Reassign Assignment',
            REASSIGN_ASSIGNMENT_SUBMIT: 'Reassign Assignment',
            COMMENCE: 'Commence',
            COMPLETE: 'Complete',
            SKIP: 'Skip',
            ALARM: 'Alarm',
            CANCEL_JOB: 'Are you sure?',
            CANCEL_JOB_ADD: 'Are you sure you want to perform this action?',

            APPROVE: 'Approve',
            REJECT: 'Reject',

            ACKNOWLEDGE: 'Acknowledge',
            REASSIGN: 'Reassign',
            CANCEL: 'Cancel',
            INSIST: 'Insist',

            REQUEST_PROPOSAL: 'Request New SLA',
            ACCEPT_PROPOSAL: 'Accept New SLA',
            REJECT_PROPOSAL: 'Reject New SLA',
            CANCEL_PROPOSAL: 'Cancel New SLA',

            REQUEST_EXTENSION: 'Request Extension',
            ACCEPT_EXTENSION: 'Accept Extension Request',
            REJECT_EXTENSION: 'Reject Extension Request',
            CANCEL_EXTENSION: 'Cancel Extension Request',

            CHANGE_ACTION_REQUIRED_BY: 'Postpone Action',

            REOPEN: 'Reopen',
            ESCALATE_APPROVAL: 'Escalate Approval',

            ACTION_REQUIRED: 'Action Required By',

            JOB_REQUISITES: 'Job Requisites',
            NO_REQUISITES_REQUIRED: 'No Requisites Required',
            REQUIREMENT_MET: 'Requirement Met',
            JOB_REQUISITES_UPDATED: 'Job Requisites Updated',
            JOB_CONTACT_ADD: 'Add',

            JOB_DIALS_PLANNED_OVERDUE: 'Overdue Planned',
            JOB_DIALS_REACTIVE_OVERDUE: 'Overdue Reactive',
            JOB_DIALS_PLANNED_REQUIRED_ATTENTION: 'Paperwork For Client Review',
            JOB_DIALS_REACTIVE_REQUIRED_ATTENTION: 'Escalated to Client',

            JOB_APPROVAL_RESENT: 'Job Approval Resent',
            JOB_ASSIGNMENT_RESENT: 'Job Assignment Resent',
            JOB_AUDIT_TRAIL_SENT: 'Audit Trail Sent',

            JOB_WITH_ID: 'Job {{ id }}',

            JOBS_REACTIVE_OVERDUE_COMPLETION: 'Reactive Jobs Overdue Completion',
            JOBS_REACTIVE_OVERDUE_ATTENDANCE: 'Reactive Jobs Overdue Attendance',
            JOBS_PLANNED_OVERDUE_COMPLETION: 'Planned Jobs Overdue Completion',
            JOB_CURRENT_OPERATIVE: 'Current Operative',
            REASSIGN_TO: 'Reassign To',

            OPERATIVE_ABSENT_BETWEEN_DATES_WARNING: 'Operative is absent between dates:',

            JOBS_REACTIVE_UPDATES_DUE: 'Reactive Jobs Updates Overdue',

            COMPLETE_TO_RAISE_ESCALATION: 'Completing this field will raise an escalation with the FM.',
            CANNOT_COMPLETE_REQUIRES_FILE_WITH_STAT_TYPE_YES: 'Unable to complete paperwork, requires a file with statutory set to yes.',

            OVERDUE: 'Overdue',
            OVERDUE_ATTENDANCE: 'Overdue Attendance',
            OVERDUE_COMPLETION: 'Overdue Completion',
            ATTEND_AT: 'Attend At',
            COMPLETE_AT: 'Complete At',
            ATTEND_BY: 'Attend By',
            COMPLETE_BY: 'Complete By',
            JOB_SITE_CONTACT: 'Job Site Contact',
            JOB_APPROVER: 'Job Approver',

            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Job Completed',

            JOB_WORKFLOW_REQUEST_EXTENSION: 'Request Extension',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Request',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Job Extension Requested',

            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Accept Extension',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accept',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Job Extension Accepted',

            JOB_WORKFLOW_REJECT_EXTENSION: 'Reject Extension',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Reject',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Job Extension Rejected',

            JOB_WORKFLOW_CANCEL_OPERATION: 'Cancel Job',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Submit',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Job Cancelled',

            JOB_WORKFLOW_REOPEN_OPERATION: 'Reopen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Reopen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Job Reopened',

            JOB_CREATED: 'Job Created',
            NO_PLANNED_JOBS: 'There are no planned jobs on this site, please select another priority',
            JOB_PO_REQUIRED: 'A PO number is required because the site has this option enabled',
            USER_IS_APPROVER_AND_TENANT: 'User is the approver and tenant',
            USER_IS_REPORTER_AND_APPROVER: 'This has been auto selected as the Reporter matches the approver',

            JOB_WORKFLOW_REJECT_APPROVAL: 'Reject Approval',
            JOB_WORKFLOW_COMMENCE_OPERATION: 'Commence',
            JOB_STATUS_CANCELLED: 'Cancelled',

            JOB_WORKFLOW_CANCEL: 'Cancel',
            JOB_WORKFLOW_CHANGED_REQUIRED_BY_DATE: 'Postpone Action',
            JOB_WORKFLOW_REJECT_ASSIGNMENT: 'Reject Assignment',

            CANCEL_OPERATION_SUCCESS: 'Job Cancelled',
            REASSIGN_ASSIGNMENT_SUCCESS: 'Job Reassigned',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUCCESS: 'Job Reassigned',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUCCESS: 'Operative Reassigned',
            REOPEN_OPERATION_SUCCESS: 'Job Reopened',
            REJECT_APPROVAL_SUCCESS: 'Job Approval Rejected',
            ESCALATE_APPROVAL_SUCCESS: 'Job Approval Escalated',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUCCESS: 'Job Approved',
            REQUEST_PROPOSAL_SUCCESS: 'New SLA Proposal Requested',
            ACCEPT_PROPOSAL_SUCCESS: 'New SLA Accepted',
            REJECT_PROPOSAL_SUCCESS: 'New SLA Rejected',
            CANCEL_PROPOSAL_SUCCESS: 'New SLA Cancelled',
            INSIST_ASSIGNMENT_SUCCESS: 'Job Insisted',
            ACCEPT_ASSIGNMENT_SUCCESS: 'Job Assigned',
            REJECT_ASSIGNMENT_SUCCESS: 'Job Rejected',
            COMMENCE_OPERATION_SUCCESS: 'Job Commenced',
            REQUEST_EXTENSION_SUCCESS: 'Job Extended',
            REJECT_EXTENSION_SUCCESS: 'Job Extension Rejected',
            ACCEPT_EXTENSION_SUCCESS: 'Job Extension Accepted',
            CANCEL_EXTENSION_SUCCESS: 'Job Extension Cancelled',

            JOB_WORKFLOW_REQUEST_PROPOSAL_SUBMIT: 'Request',
            JOB_WORKFLOW_INSIST_ASSIGNMENT_SUBMIT: 'Insist',
            JOB_WORKFLOW_REJECT_APPROVAL_SUBMIT: 'Reject',
            JOB_WORKFLOW_ESCALATE_APPROVAL_SUBMIT: 'Escalate',
            JOB_WORKFLOW_ACCEPT_ASSIGNMENT_SUBMIT: 'Accept',
            JOB_WORKFLOW_COMMENCE_OPERATION_SUBMIT: 'Commence',
            JOB_WORKFLOW_REJECT_ASSIGNMENT_SUBMIT: 'Reject',
            JOB_WORKFLOW_REASSIGN_ASSIGNMENT_SUBMIT: 'Reassign',
            JOB_WORKFLOW_REASSIGN_OPERATIVE_SUBMIT: 'Reassign',
            ACTION_REQUIRED_BETWEEN: 'Action Required Between',
            ATTENDANCE_DUE_AT_BETWEEN: 'Attendance Due At Between',
            COMPLETION_DUE_AT_BETWEEN: 'Completion Due At Between',

            PREVIOUS_OPERATIVE: 'Previous Operative',
            NEW_OPERATIVE: 'New Operative',
            JOB_NUMBER: 'Job Number',
            PLANNED: 'Planned',
            REACTIVE: 'Reactive',

            CANCEL_JOBS: 'Cancel Jobs',
            JOBS_SELECTED: 'Jobs Selected',
            ARE_YOU_SURE_YOU_WOULD_LIKE_TO_CANCEL_THESE_JOBS: 'Are you sure you would like to cancel these jobs?',
            EMAIL_CANCELLATIONS_TO_ENGINEERS: 'Email Cancellations to Engineers',
            JOBS_QUEUED_FOR_REASSIGNMENT: 'Jobs Queued for Reassignment',
            JOBS_QUEUED_FOR_CANCELLATION: 'Jobs Queued for Cancellation',

            DATE_NOT_SOONER_THAN_1_HOUR: 'You can not request a date sooner than 1 hour from now',

            ALL_FILTERED_JOBS: 'All Filtered Jobs',

            SELECT_SITE: 'Select a site',
            LAST_FIVE_JOBS: 'Last 5 jobs',
            STATUTORY: 'Statutory',
            NOT_STATUTORY: 'Not Statutory',

            PO_NUMBER_INVALID_LENGTH: 'PO Number should be no more than 40 characters in length',
            EDIT_PO_NUMBER: 'Edit PO Number',
            EDIT_PO: 'Edit PO',
            PO_NUMBER: 'PO Number',
            PO_NOTE: 'Note (optional)',
            JOB_PO_NUMBER_UPDATED: 'Job PO Number Updated Successfully',

            JOBS_POINT_IN_TIME: 'Jobs at this point in time match your search criteria',
            JOB_LINKS_JOB_ID: 'Job #',
            JOB_LINKS_QUOTE_ID: 'Quote #',
            JOB_LINKS_ACTION_ID: 'Action #',
            JOB_LINK: 'Link',
            JOB_LINK_WITH: 'With',
            JOB_LINK_TO: 'To',
            JOB_LINK_FROM: 'From',
            JOB_LINK_0: 'To',
            JOB_LINK_1: 'From',
            JOB_LINK_2: 'With',
            JOB_EXTENSION_REQUEST_DATE_NOT_SOONER_THAN_SLA: 'You can not request a date sooner than the already instated SLA',

            JOB_LABOUR: 'Job Labour',
            JOB_LABOUR_ADD: 'Add Job Labour',
            JOB_LABOUR_DELETED: 'Job Labour Deleted',
            JOB_LABOUR_EDIT: 'Edit Job Labour',
            JOB_LABOUR_UPDATED: 'Updated Job Labour',
            JOB_LABOUR_CREATED: 'Created Job Labour',
            JOB_LABOUR_LIST: 'Job Labour Lines',
            LABOUR_LINES: 'Labour Lines',
            TIME_WORKED: 'Time Worked',
            TIME_TRAVELLED: 'Time Travelled',
            COMPLETION_BEFORE_ATTENDANCE: 'Completion date must not be before attendance date',
            JOBS_RATIO: 'Jobs Ratio',
            MILEAGE: 'Mileage',
            MINUTES_TRAVELLED: 'Minutes Travelled',
            MINUTES_WORKED: 'Minutes Worked',
            SUBMITTED_AT: 'Submitted At',
            JOB_COMPLETE_LABOURS_REQUIRED: 'Labour Line required for Job Completion',
            JOB_COMPLETE_NO_LABOURS: 'No labour lines',

            LOCATION_REQUIRED_JOB: 'Require Location for Reactive Jobs',
            LOCATION_ID: 'Location ID',
            NO_JOB_VISIBILITY_ERROR: 'You do not have permission to view this job',

            AWAITING_PAPERWORK_SINCE: 'Awaiting Paperwork Since (hours)',
            AWAITING_PAPERWORK: 'Awaiting Paperwork',
            JOB_PAPERWORK_REJECTED_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> rejected the paperwork.',

            JOB_VALUE: 'Value',
            JOB_VALUE_ADD: 'Add Job Values',
            JOB_VALUE_APPROVE: 'Approve Job Values',
            JOB_VALUE_APPROVE_DESCRIPTION: 'The description that should be used on the invoice',
            JOB_VALUE_REJECT: 'Reject Job Values',
            JOB_VALUE_REJECT_DESCRIPTION: 'The reason for rejecting these values',
            JOB_VALUE_LABOUR: 'Labour Value',
            JOB_VALUE_MATERIAL: 'Materials Value',
            JOB_VALUE_LABOUR_WITH_VALUE: 'Labour Value {{ value | number:2 }}',
            JOB_VALUE_MATERIAL_WITH_VALUE: 'Materials Value {{ value | number:2 }}',
            JOB_TOTAL_VALUE_WITH_VALUE: 'Total Value {{ value | number:2 }}',
            JOB_VALUE_APPROVE_CREATED: 'Job Value Approved',
            JOB_VALUE_SEPARATE_INVOICE: 'Separate Invoice',
            JOB_VALUE_INVOICE_NUMBER: 'Invoice Number',
            JOB_VALUE_INVOICE_DATE: 'Invoice Date',
            JOB_VALUE_PREVIOUS_VALUES: 'Previous Values',
            JOB_TOTAL_VALUE: 'Total Value',
            JOB_FOC_ADD: 'FoC',
            JOB_FOC: 'Mark as Free Of Charge',
            JOB_FOC_SUBMIT: 'Submit',
            JOB_FOC_CREATED:'Job marked as Free of Charge',
            CIS_VALUE: 'CIS',
            CIS_VALUE_LABEL: 'Is the Domestic VAT Reverse Charge to be applied to this job?',
            CIS_VALUE_LABEL_HELP_TEXT: '(The DRC must be applied to any works that are within the Construction Industry Scheme).',

            JOB_PRIMARY_OPERATIVE_APPROVAL: 'Primary Operative Approval',
            JOB_APPROVER_APPROVAL: 'Job Approver Approval',
            JOB_PENDING_WITH_ME: 'Pending with me',

            EXPLICIT_ACCESS: 'Explicit Access',
            EXPLICIT_ACCESS_LIST: 'Explicit Access',
            EXPLICIT_ACCESS_ADD: 'Grant Access',
            EXPLICIT_ACCESS_EDIT: 'Edit',
            ACCESS_GRANTED: 'Access Granted',
            ACCESS_REVOKED: 'Access Revoked',
            DATE_NOT_IN_RANGE: 'Date not in allowed range',

            ORIGINAL_VALUES: 'Original Values',
            MARKEDUP_VALUES: 'Marked Up Values',

            JOBS_DUE_BY: 'Due By',
            JOBS_DUE_BY_TYPE: 'Type',
            JOBS_DUE_BY_ATTENDANCE: 'Attendance',
            JOBS_DUE_BY_COMPLETION: 'Completion',
            JOBS_DUE_BY_EITHER: 'Either',
            JOBS_DUE_BY_FREQUENCY: 'Frequency',
            JOBS_DUE_BY_DAYS: 'Day',
            JOBS_DUE_BY_WEEKS: 'Week',
            JOBS_DUE_BY_MONTHS: 'Month',
            JOBS_DUE_BY_INTERVAL: 'Interval',

            WAIVE_MANAGEMENT_FEE: 'Waive Management Fee',
            WAIVE_MANAGEMENT_FEE_SUBMIT: 'Submit',
            JOB_WAIVE_MANAGEMENT_FEE: 'Management Fee Waived',
            JOB_WAIVE_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> waived the management fee.',
            UPHOLD_MANAGEMENT_FEE: 'Uphold Management Fee',
            UPHOLD_MANAGEMENT_FEE_SUBMIT: 'Submit',
            JOB_UPHOLD_MANAGEMENT_FEE: 'Management Fee Upheld',
            JOB_UPHOLD_MANAGEMENT_FEE_ACTION: '<strong>{{ ::event._links["user"].title }}</strong> upheld the management fee.',

            ORIGINATING_JOB_DETAILS: 'Originating Job Details',
            REMEDIALS: 'Remedials',
            REMEDIALS_ACTIONS: 'Remedial Actions',
            REMEDIALS_RAISE: 'Raise Remedials',
            SERVICE_PROVIDER_REQUIRED_FOR_FUTURE_JOBS: 'A Service Provider is required to schedule this visit',
            RAISE_QUOTE: 'Raise Quote',
            JOB_FOUR_D: '4D',
            JOB_LABOUR_TYPE: 'Labour Type',
            JOB_ADDITIONAL_OPERATIVE_REMOVED: 'Additional Operative Removed Successfully',
            ADDITIONAL_OPERATIVES: 'Additional Operatives',
            ADD_ADDTIONAL_OPERATIVE: 'Add Additional Operative',
            ADD_ADDTIONAL_OPERATIVE_SUCCESS: 'Additional Operative Created Successfully',
            ADD_ADDTIONAL_OPERATIVE_SUBMIT: 'Add',
            ADDITIONAL_OPERATIVE_EXISTS: 'Additional operative already exists.',
            ADDITIONAL_OPERATIVE_SAME_AS_PRIMARY_OPERATIVE: 'Additional operative can\'t be same as the job main operative.',
            REQUIRED_DOCUMENT_TYPE: 'Required Document Type',
            REQUIRED_DOCUMENT_TYPES: 'Required Document Types: ',
            NO_REQUIRED_DOCUMENT: 'No Document Required',
            DOCUMENT_REQUIRED: 'Document Required',
            REQUIRED_DOCUMENT_TYPE_NAME: 'Required Doc. Type',
            MISSING_DOCUMENTS : 'Some Required Document Types Must Be Uploaded',
            REQUIRED_DOCUMENT_TYPES_MISSING: 'This job is still awaiting paperwork. Please upload: ',
            REQUIRE_DOCUMENT_TYPE_WHEN_RAISING_REACTIVE_JOB_TOOLTIP: 'A Document Type is required when raising a Reactive Job',
            REASON_FOR_OPERATIVE_REMOVAL: 'Please write a reason of operative removal',
            SFG_WARNING: 'This job has requirements specific to SFG20. Please review the requirements in the Service Routine tab or visit the SFG20 website for more details',
            PROSURE_STATUS_AT_APPROVAL: 'Status at Approval',
            PROSURE_STATUS_AT_COMPLETION: 'Status at Completion',
            PROSURE360_STATUS_AT_COMPLETION: 'Prosure360 Status at Completion',
            PROSURE360_STATUS_AT_APPROVAL: 'Prosure360 Status at Approval',
            SYSTEM_CREATED_JOB: 'The system automatically generated the job based on a PPM Schedule.'
        });
    }
})();
