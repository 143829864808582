(function () {
    'use strict';

    angular
        .module('elogbooks.common.dashboard-layout')
        .config(registerRoutes);

    function registerRoutes ($stateProvider) {

        $stateProvider
            .state('dashboard', {
                abstract: true,
                resolve: {
                    // Root resource for logged in user
                    rootResourceResponse: function (apiClient, $rootScope) {
                        let url = '/';

                        if (window.localStorage.getItem('originalUser') === null) {
                            url = '/?dashboard=1';
                        }

                        return apiClient.get(url).then(function(response) {
                            $rootScope.theme = response?.theme;
                            return response;
                        });
                    },
                    user: function (userManager, rootResourceResponse, $q, $state, localeManager, ssoService) {
                        if (!rootResourceResponse.getLink('user')) {
                            return $q.reject('NOT_AUTHENTICATED');
                        }

                        return userManager.getUser().then(function (userResponse) {
                            if ($state.next.name !== 'dashboard.change-password' && userResponse.shouldChangePassword && ! userManager.skippedChangePassword) {
                                $state.go('dashboard.change-password');
                            }

                            if ($state.next.name !== 'sso' && !userManager.originalUser) {
                                ssoService.forceUserToLinkAccount(userResponse.getLink('sso-user'));
                            }

                            localeManager.set(userManager.getPreferenceValue('locale'));
                            return userResponse;
                        })
                    },
                    userPreferences: function ($stateParams, userManager) {
                        return userManager.getPreferences($stateParams.reloadUserPreferences);
                    },
                    siteCollectionResponse: function(apiClient, user, globalFilterParamsService) {
                        var params = {
                            datagroup: 'search'
                        };

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userCollectionResponse: function(rootResourceResponse, apiClient, user) {
                        if (window.localStorage.getItem('siteAssociateTypeFilter')) {
                            var params = { status: 'active' };
                            params.siteAssociateType = window.localStorage.getItem('siteAssociateTypeFilter') || null;

                            return apiClient.get(user.getLink('users'), params);
                        }
                        return null;
                    },
                    modules: function (modulesService) {
                        return modulesService.getAll();
                    },
                    modulesEnabled: function (apiClient, modulesService, rootResourceResponse) {
                        if (rootResourceResponse.getLink('modules') !== null) {
                            return apiClient.get(rootResourceResponse.getLink('modules'), null, true).then(function (moduleResponse) {
                                var modulesEnabled = {};
                                if (moduleResponse) {
                                    angular.forEach(moduleResponse.settings, function (value, index) {
                                        var name = value.name.split(':')[0];
                                        modulesEnabled[name] = value.value;
                                    });
                                }
                                modulesService.enabled = modulesEnabled;

                                return modulesEnabled;
                            });
                        }

                        return [];
                    },
                    settings: function (rootResourceResponse, $window, elbSettings) {
                        if (rootResourceResponse.getLink('settings') !== null) {
                            $window.localStorage.setItem('settingsUrl', rootResourceResponse.getLink('settings'));

                            return elbSettings.getSettings(true, rootResourceResponse.getLink('settings'));
                        }

                        return [];
                    }
                },
                params: {
                    reloadUserPreferences: null
                },
                ncyBreadcrumb: {
                    skip: true
                },
                templateUrl: '/modules/common/dashboard-layout/dashboard-layout.html',
                controller: 'DashboardLayoutController',
                controllerAs: 'vm'
            });
    }

})();
